import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueSession from 'vue-session'
import VueGtag from 'vue-gtag';

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

Vue.use(VueSession, {
  persist: true
})

Vue.use(VueGtag, {
  config: { id: "G-46M9YCR0CY" },
}, router)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
