<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <v-img
          :src="require('../assets/LogoLeoLearning.png')"
          class="my-3"
          contain
          height="250"
        />
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="3" class="d-none d-lg-flex">
      </v-col>
      <v-col>
        <v-card class="card" elevation="2">
          <p class="text-h6">
            Le site de recrutement de LéoLearning est actuellement fermé, il rouvrira la semaine du 04/10/2021 pour les tuteurs uniquement.<br>
          </p>  
        </v-card>
      </v-col>
      <v-col cols="3" class="d-none d-lg-flex">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ClosedPage',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card{
  padding: 2rem;
}
</style>
        