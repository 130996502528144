<template>
  <div>
    <closed-page v-if="isClosed"/>
    <home-page v-else/>
  </div>
    
</template>

<script>
import HomePage from '../components/HomePage.vue'
import ClosedPage from '../components/ClosedPage.vue'

  export default {
    name: 'Home',
    data: () => ({
      isClosed: false
    }),
    components: {
      HomePage,
      ClosedPage,
    },
  }
</script>
