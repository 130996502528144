<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <v-img
          :src="require('../assets/LogoLeoLearning.png')"
          class="my-3"
          contain
          height="250"
        />
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="3" class="d-none d-lg-flex">
      </v-col>
      <v-col>
        <v-card class="card" elevation="2">
          <p class="text-h6">
            Bienvenue sur le site de recrutement de LéoLearning, l'association de tutorat du pôle Léonard De Vinci. <br>
            Sur cette plateforme tu pourras commencer le processus de recrutement 😉
          </p>
          <v-row class="text-center mt-2">
            <v-col>
              <v-btn
                color="primary"
                class="white--text"
                @click="$router.push('inscription')"
              >
                POSTULER
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="3" class="d-none d-lg-flex">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HomePage',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card{
  padding: 2rem;
}
</style>
