<template>
  <v-app>
    <v-app-bar
      app
      color="#c8c8c8"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="LeoLearning Logo"
          class="shrink mr-2"
          contain
          src="./assets/LogoSimple.png"
          transition="scale-transition"
          width="50"
          @click="$router.push('/').catch(err => {})"
        />

        <v-img
          alt="LeoLearning Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="./assets/TitreSimple.png"
          width="150"
          @click="$router.push('/').catch(err => {})"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://www.instagram.com/leolearningpulv/"
        target="_blank"
        text>
        <v-icon class="fab fa-instagram"></v-icon>
      </v-btn>

      <v-btn
        href="https://www.facebook.com/LeoLearningPULV"
        target="_blank"
        text>
        <v-icon class="fab fa-facebook"></v-icon>
      </v-btn>

      <v-btn
        href="https://www.linkedin.com/company/léolearning/"
        target="_blank"
        text>
        <v-icon class="fab fa-linkedin"></v-icon>
      </v-btn>

      <v-btn
        href="https://discord.gg/QrMuXZYfCj"
        target="_blank"
        text>
        <v-icon class="fab fa-discord"></v-icon>
      </v-btn>

      <v-btn
        v-if="$session.exists()"
        color="primary"
        class="white--text mr-3"
        @click="$router.push('/dashboard')">
      Dashboard
      </v-btn>

      <v-btn
        v-if="$session.exists()"
        color="secondary"
        class="white--text"
        @click="$session.destroy(); $router.push('/')">
      Deconnexion
      </v-btn>

    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),

  methods:{

  }
};
</script>
